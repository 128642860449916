import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
//import brushMan from '../../../Images/brushman.png';
//import HeroMainbg from '../../../Images/hero-main-bg.png';
//import injectTool from '../../../Images/inject-tool.png';
//import inject from '../../../Images/injection.png';
//import staircaseMan from '../../../Images/staircase.png';
import parking from '../../../Images/Services-Banner-v011.gif';
import './Banner.css';
const Banner = () => {
    return (
        <section className="banner-all text-white">
            <Container>
                <Row className="align-items-center">
                    <Col md={24} lg={24} sm={24}>
                        <div className="section-title">
                            <h1>Blog Posts</h1>
                        </div>
                        <div className="breadcrumb-nav">

                            <a href="/" className="text-decoration-none text-white">Home Page</a>
                            <Link to="/blog" className="text-decoration-none text-white home-page">Blog Post List</Link>
                        </div>
                    </Col>
                    
                </Row>
            </Container>
        </section>
    );
};

export default Banner;
