import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import expertDentist from '../../../Images/experienceddentist.jpg';
import './Dentist.css';

const Dentist = () => {
    return (
        <section className='expert-dentist'>
            <Container>
                <Row className="align-items-center">
                    <Col lg={6}>
                        <img src={expertDentist} alt="expertDentist" className="img-fluid" />
                    </Col>
                    <Col lg={6}>
                        <div className="expertDentist-txt mt-5 mt-lg-0">
                            <h2>Expertise and Dedication</h2>
                            <p>Our team's expertise in non-emergency medical transportation is unmatched. With years of combined experience in healthcare and logistics, we bring a depth of knowledge and a dedication to service that sets us apart. Our drivers, customer service representatives, and operational staff all share a unified mission: to provide an exceptional transportation experience that contributes positively to the healthcare journey of our clients.</p>
                            <p>By focusing on these pillars, Alliant Care Transport is not just a transportation service; we are a vital link in the healthcare system of San Diego, dedicated to enhancing the well-being and accessibility of care for all our clients.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Dentist;
