import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
//import brushMan from '../../../Images/brushman.png';
//import HeroMainbg1 from '../../../Images/hero-main-bg.png';
//import injectTool from '../../../Images/inject-tool.png';
//import inject from '../../../Images/injection.png';
//import staircaseMan from '../../../Images/staircase.png';
//import wheelchair from '../../../Images/Wheelchair.png';
//import HeroMainbg from '../../../Images/Services-Banner.png';
import parking from '../../../Images/Services-Banner-v011.gif';
const Banner = () => {
    return (
        <section className="banner-all text-white">
            <Container>
                <Row className="align-items-center">
                    <Col md={6} lg={6} sm={12}>
                        <div className="section-title">
                            <h1>Transportation Solutions</h1>
                        </div>
                    </Col>
                    <Col md={6} lg={6} sm={12}>
                        <div className="hero-slide-right text-start">
                            <div className="banner-animate">
                                <img src={parking} alt="" className="img-fluid" width="450" height="450"/>
                                {/* 
                                <img src={wheelchair} alt="" className="img-fluid a2" />
                                <img src={brushMan} alt="" className="img-fluid a3" />
                                <img src={inject} alt="" className="img-fluid a4" />
                                <img src={injectTool} alt="" className="img-fluid a5" /> 
                                */}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Banner;
