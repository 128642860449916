import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import nemtPromoBanner from '../../../Images/doctor-nurse.png'; // Placeholder for a NEMT-related image
import './Promo.css';

const Promo = () => {
    return (
        <section className="promo-wrapper">
            <Container>
                <Row className="align-items-center">
                    <Col md={12} sm={12} lg={6}>
                        <div className="promo-content text-white text-start">
                            <h1 className="mt-sm-req">Schedule your ride and experience exceptional care on the go!</h1>
                            {/* <Link to="/login"><button className="theme-btn btn-fill mt-4">Book a Ride</button></Link> */}
                            <Link to="/contact"><button className="theme-btn btn-fill mt-4">Book a Ride</button></Link>
                        </div>
                    </Col>
                    <Col md={12} sm={12} lg={6}>
                        <div className="promo-banner">
                            <img src={nemtPromoBanner} alt="Non-Emergency Medical Transportation" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Promo;

