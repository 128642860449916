export const FakeService = [
    {
        "id": "001",
        "title": "Wheelchair Transportation",
        "description": "Offering safe, reliable, and comfortable wheelchair-accessible transportation for medical appointments, hospital discharges, and more.",
        "link": "Read More",
        "img": "https://yourstorage.example.com/path/to/wheelchair_transport_icon.svg" // Placeholder for a wheelchair transport icon
    },
    {
        "id": "002",
        "title": "Stretcher (Gurney) Transport",
        "description": "Specialized stretcher vehicles for non-ambulatory patients, ensuring a smooth and secure ride to and from medical facilities.",
        "link": "Read More",
        "img": "https://yourstorage.example.com/path/to/stretcher_transport_icon.svg" // Placeholder for a stretcher transport icon
    },
    {
        "id": "003",
        "title": "Ambulatory Transportation",
        "description": "Dependable and comfortable transportation for ambulatory patients, perfect for those who can walk on their own but need a ride.",
        "link": "Read More",
        "img": "https://yourstorage.example.com/path/to/ambulatory_transport_icon.svg" // Placeholder for an ambulatory transport icon
    },
    {
        "id": "004",
        "title": "Door-to-Door Service",
        "description": "Our drivers offer door-to-door service, assisting you from your home directly to your appointment, with a helping hand every step of the way.",
        "link": "Read More",
        "img": "https://yourstorage.example.com/path/to/door_to_door_service_icon.svg" // Placeholder for a door-to-door service icon
    },
    {
        "id": "005",
        "title": "Non-Emergency Hospital Visits",
        "description": "Reliable transportation for non-emergency hospital visits, ensuring you get to your appointments on time and stress-free.",
        "link": "Read More",
        "img": "https://yourstorage.example.com/path/to/hospital_visits_icon.svg" // Placeholder for a non-emergency hospital visit icon
    },
    {
        "id": "006",
        "title": "Specialized Medical Appointments",
        "description": "We cater to various specialized medical appointments including dialysis, rehabilitation, and more, focusing on your health and recovery.",
        "link": "Read More",
        "img": "https://yourstorage.example.com/path/to/specialized_appointments_icon.svg" // Placeholder for a specialized medical appointments icon
    }
]

