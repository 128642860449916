import React from 'react';
import { Col, Container, NavLink, Row } from 'react-bootstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import './Footer.css'

const Footer = () => {
    return (
        <div className="footer-bg">
            <Container>
                <Row className="text-white">
                    <Col xs={6} md={3}>
                        <div className="single-footer-widget">
                            <div className="widget-title">
                                <h2>About Us</h2>
                            </div>
                            <div className="widget-content">
                                <RRNavLink to="/about" className="footer-link">Our Mission</RRNavLink>
                                <RRNavLink to="/contact" className="footer-link">FAQ's</RRNavLink>
                                <RRNavLink to="/about" className="footer-link">Company Profile</RRNavLink>
                                <RRNavLink to="/contact" className="footer-link">Contact Us</RRNavLink>
                                <RRNavLink to="/blog" className="footer-link">Blog</RRNavLink>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="single-footer-widget">
                            <div className="widget-title">
                                <h2>Services</h2>
                            </div>
                            <div className="widget-content">
                                <RRNavLink to="/service" className="footer-link">Wheelchair Transport</RRNavLink>
                                <RRNavLink to="/service" className="footer-link">Stretcher Transport</RRNavLink>
                                <RRNavLink to="/service" className="footer-link">Ambulatory Transport</RRNavLink>
                                <RRNavLink to="/service" className="footer-link">Door-to-Door Service</RRNavLink>
                                <RRNavLink to="/service" className="footer-link">Non-Emergency Hospital Visits</RRNavLink>
                                <RRNavLink to="/service" className="footer-link">Specialized Medical Appointments</RRNavLink>
                            </div>
                        </div>
                    </Col>
                    
                    {/*<Col xs={6} md={3}>
                        <div className="single-footer-widget">
                            <div className="widget-title">
                                <h2>Patient Resources</h2>
                            </div>
                            <div className="widget-content">
                                <NavLink className="footer-link">New Patients</NavLink>
                                <NavLink className="footer-link">Meet the Team</NavLink>
                                <NavLink className="footer-link">Service Areas</NavLink>
                                <NavLink className="footer-link">Insurance and Payments</NavLink>
                                <NavLink className="footer-link">Account Login</NavLink>
                            </div>
                        </div>
                    </Col>*/}
                    
                    <Col xs={6} md={3}>
                        <div className="single-footer-widget">
                            <div className="widget-title">
                                <h2>Contact Us</h2>
                            </div>
                            <div className="widget-content">
                                <NavLink className="footer-link">Alliant Care Transport</NavLink>
                                <NavLink className="footer-link">San Diego, CA</NavLink>
                                <NavLink className="footer-link" href="tel:800-292-3100">800-292-3100</NavLink>
                                <NavLink className="footer-link"href="mailto:info@alliant.care">info@alliant.care</NavLink>
                                {/*<NavLink className="footer-link">Fax: +1 800 292 3100</NavLink>*/}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="footer-copy-right text-center text-white">
                <p className='mb-0'>&copy; 2024 - <span className="developer">Alliant Care Transport</span> | All Rights Reserved</p>
            </div>
        </div>
    );
};

export default Footer;

