import React from 'react';
import { Link } from 'react-router-dom';
import Banner from '../Banner/Banner';
import blogPosts from './blogPosts';
import './BlogList.css'; // Import the CSS file

const BlogList = () => {
  return (
    <div>
      <Banner />
      <h1 className="blog-list-title">List of Blog Posts</h1>
      <ul className="blog-list">
        {blogPosts.map(post => (
          <li key={post.id} className="blog-list-item">
            <Link to={`/blog/${post.id}`} className="post-link">
              {post.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BlogList;
