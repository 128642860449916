import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import serviceDetailImg from '../../../Images/service-details-promo1.png';
import serviceDetailImg2 from '../../../Images/service-details-promo2.png';
import wheelchairTransportImg from '../../../Images/wheelchairTransportImg.png'; // Update with actual path
import stretcherTransportImg from '../../../Images/stretcherTransportImg.png'; // Update with actual path
import ambulatoryTransportImg from '../../../Images/ambulatoryTransportImg.png'; // Update with actual path
import doorToDoorServiceImg from '../../../Images/doorToDoorServiceImg.png'; // Update with actual path
import hospitalVisitsImg from '../../../Images/hospitalVisitsImg.png'; // Update with actual path
import specializedAppointmentsImg from '../../../Images/specializedAppointmentsImg.png'; // Update with actual path
import './Detail.css';

const Detail = () => {
    return (
        <>
            <section className="service-detail p-5">
                <Container>
                    {/* Wheelchair Transportation */}
                    <Row className="align-items-center p-5">
                        <Col lg={6} sm={12} xs={12}>
                            <div className="service-txt mt-5 mt-lg-0">
                                <h2 className="fw-bold">Wheelchair Transportation</h2>
                                <p>Ensuring safe, reliable, and comfortable wheelchair-accessible transportation for medical appointments, hospital discharges, and more. Our vehicles are fully equipped with the necessary features to support and secure wheelchair-bound passengers throughout their journey.</p>
                            </div>
                        </Col>
                        <Col lg={6} sm={12} xs={12}>
                            <img src={wheelchairTransportImg} alt="Wheelchair Accessible Vehicle" className="img-fluid" />
                        </Col>
                    </Row>

                    {/* Stretcher (Gurney) Transport */}
                    <Row className="align-items-center p-5">
                        <Col lg={6} sm={12} xs={12}>
                            <img src={stretcherTransportImg} alt="Stretcher Transportation" className="img-fluid pt-xs-5" />
                        </Col>
                        <Col lg={6} sm={12} xs={12}>
                            <div className="service-txt mt-5 mt-lg-0">
                                <h2 className="fw-bold">Stretcher (Gurney) Transport</h2>
                                <p>For non-ambulatory patients, our stretcher service offers a secure and comfortable option. Equipped with specialized vehicles and trained staff, we provide a smooth and dignified transfer experience for all our clients.</p>
                            </div>
                        </Col>
                    </Row>

                    {/* Ambulatory Transportation */}
                    <Row className="align-items-center p-5">
                        <Col lg={6} sm={12} xs={12}>
                            <div className="service-txt mt-5 mt-lg-0">
                                <h2 className="fw-bold">Ambulatory Transportation</h2>
                                <p>Our ambulatory transportation service is perfect for clients who can walk on their own but require assistance or do not have access to transportation. We offer a comfortable and reliable ride for all your medical appointments.</p>
                            </div>
                        </Col>
                        <Col lg={6} sm={12} xs={12}>
                            <img src={ambulatoryTransportImg} alt="Ambulatory Transportation" className="img-fluid" />
                        </Col>
                    </Row>

                    {/* Door-to-Door Service */}
                    <Row className="align-items-center p-5">
                        <Col lg={6} sm={12} xs={12}>
                            <img src={doorToDoorServiceImg} alt="Door-to-Door Service" className="img-fluid pt-xs-5" />
                        </Col>
                        <Col lg={6} sm={12} xs={12}>
                            <div className="service-txt mt-5 mt-lg-0">
                                <h2 className="fw-bold">Door-to-Door Service</h2>
                                <p>From your home to your appointment and back, our door-to-door service ensures you have a helping hand at every step. Our drivers are trained to assist you, offering personalized care and attention throughout your journey.</p>
                            </div>
                        </Col>
                    </Row>

                    {/* Non-Emergency Hospital Visits */}
                    <Row className="align-items-center p-5">
                        <Col lg={6} sm={12} xs={12}>
                            <div className="service-txt mt-5 mt-lg-0">
                                <h2 className="fw-bold">Non-Emergency Hospital Visits</h2>
                                <p>Reliable transportation for non-emergency hospital visits is crucial. Our service ensures you get to your appointments on time, without the stress of navigating transportation on your own.</p>
                            </div>
                        </Col>
                        <Col lg={6} sm={12} xs={12}>
                            <img src={hospitalVisitsImg} alt="Non-Emergency Hospital Visits" className="img-fluid" />
                        </Col>
                    </Row>

                    {/* Specialized Medical Appointments */}
                    <Row className="align-items-center p-5">
                        <Col lg={6} sm={12} xs={12}>
                            <img src={specializedAppointmentsImg} alt="Specialized Medical Appointments" className="img-fluid pt-xs-5" />
                        </Col>
                        <Col lg={6} sm={12} xs={12}>
                            <div className="service-txt mt-5 mt-lg-0">
                                <h2 className="fw-bold">Specialized Medical Appointments</h2>
                                <p>Transportation to and from specialized medical appointments, including dialysis, rehabilitation, and more, is offered with the highest level of care and professionalism. We're dedicated to supporting your health journey every step of the way.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};
export default Detail;
