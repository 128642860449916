import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Content.css';

const Content = () => {
    return (
        <section className="about-content-sec">
            <Container>
                <Row>
                    <Col md={12} lg={8} lg={{ order: 2 }} className="text-center">
                        <div className="section-title">
                            <h1>Our Transportation Service</h1>
                        </div>
                        <p className="w-50 m-auto content-inner">Since its launch, Alliant Care Transport has quickly become a trusted name in non-emergency medical transportation within San Diego, California.</p>
                  	<p className="w-50 m-auto content-inner">At Alliant Care Transport, we stand at the forefront of non-emergency medical transportation in San Diego, California. Our commitment to excellence is evident in every aspect of our operation. From our meticulously maintained vehicles equipped with the latest safety and comfort features to our rigorously trained professional staff, we ensure a personalized and secure travel experience that is unmatched in the industry. We understand the critical role transportation plays in healthcare access and strive to bridge that gap with services that exceed expectations.</p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Content;
