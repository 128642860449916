import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory
import './Appointment.css';

const Appointment = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });
    const [successMessage, setSuccessMessage] = useState('');

    const navigate = useNavigate(); // Use useNavigate hook

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();  // Prevent default form submission behavior
        try {
            const response = await fetch('https://sch.alliant.care/appointments', {
            //const response = await fetch('http://localhost:5000/appointments', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                setSuccessMessage('Your request has been "successfully" submitted! Someone will be in contact with you shortly.');
                setFormData({ name: '', email: '', phone: '', subject: '', message: '' });  // Clear form
            } else {
                throw new Error('Something went wrong');
            }
        } catch (error) {
            console.error('Failed to submit form:', error);
            setSuccessMessage('Failed to submit the request.');
        }
    };

    return (
        <section className="appointment-wrapper">
            <Container>
                <Row>
                    <Col sm={12} md={12}>
                        <div className="section-title">
                            <h1 className="mt-5">Request a Ride</h1>
                        </div>
                        <div className="appointment-form">
                            <form onSubmit={handleSubmit} className="row">
                                <Col md={6} lg={6}>
                                    <input type="text" placeholder="Name" name="name" value={formData.name} onChange={handleChange} />
                                </Col>
                                <Col md={6} lg={6}>
                                    <input type="email" placeholder="Email" name="email" value={formData.email} onChange={handleChange} />
                                </Col>
                                <Col md={6} lg={6}>
                                    <input type="phone" placeholder="Phone" name="phone" value={formData.phone} onChange={handleChange} />
                                </Col>
                                <Col md={6} lg={6}>
                                    <input type="text" placeholder="Subject" name="subject" value={formData.subject} onChange={handleChange} />
                                </Col>
                                <Col md={12} lg={12}>
                                    <textarea name="message" cols="30" rows="10" placeholder="Message" value={formData.message} onChange={handleChange}></textarea>
                                </Col>
                                <button type="submit" className="theme-btn btn-fill form-btn mt-5">Submit</button>
                                {successMessage && <p className="success-message">{successMessage}</p>}
                            </form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Appointment;

