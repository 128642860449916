import "@fontsource/josefin-sans";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import scene from '../../../Images/NEMT-Scene-1.png';
import ambulance from '../../../Images/AMBULANCE-1.png'
import ambulance1 from '../../../Images/NMET-transport-services-on-the-San-Di-v01.gif';
import './Banner.css';



const Banner = () => {
    return (
            <section className="single-hero-slide text-white d-flex justify-content-center align-items-center">
                <Container>
                    <Row className="align-items-center">
                        <Col md={12} sm={12} lg={6}>
                            <div className="hero-slide-left justify-content-end align-items-center text-center text-lg-start">
                                <h2>Seamless Travel for Medical Care</h2>
                                <h1>Your Partner on the Path to Wellness</h1>
                                <p className="mb-xs-5">Experience compassionate and reliable non-emergency medical transportation. Our dedicated team ensures that your journey to health is comfortable and stress-free. We're here to support you, every step of the way.</p>
                                <div className="banner-btn m-sm-auto">
                                    <Link to="/contact"><button className="theme-btn btn-fill">Request a ride</button></Link>
                                    <Link to="/service"><button className='theme-btn bth-blank'>Learn More</button></Link>
                                </div>
                            </div>
                        </Col>
                        <Col md={12} sm={12} lg={6} className="mt-sm-5">
                            <div className="hero-slide-right text-center text-lg-start mt-sm-5">
                                {/* <div className="animate-img">
                                    <img src={ambulance} alt="" className="img-fluid aimg2" />
                                </div> */}
                                
                                <img src={ambulance1} alt="" className="heroTeeth"/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
    );
};

export default Banner;
