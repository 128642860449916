import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './BlogPost.css';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Appoinment from '../../Home/Appoinment/Appoinment';
import Content from '../BlogPostContent/Content';
import Banner from '../Banner/Banner';
import blogPosts from './blogPosts';

const BlogPost = () => {
  const { id } = useParams();
  const post = blogPosts.find(post => post.id === id);
  const postIndex = blogPosts.findIndex(post => post.id === id);
  const prevPost = blogPosts[(postIndex - 1 + blogPosts.length) % blogPosts.length];
  const nextPost = blogPosts[(postIndex + 1) % blogPosts.length];
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (postIndex === -1) {
    return (
      <div>
        <Banner />
        <p>Post not found</p>
      </div>
     );
  }

  if (!post) {
    return (
      <div>
        <Banner />
        <p>Post not found</p>
      </div>
     );
  }
  

  return (
    <div>
      <Banner />
      <h1 className="post-title">{blogPosts[postIndex].title}</h1>
      <img src={blogPosts[postIndex].img} className="post-img"/>
      <div className="post-content" dangerouslySetInnerHTML={{ __html: blogPosts[postIndex].content }} />
      {/*<p className="post-content">{blogPosts[postIndex].content}</p>*/}
      <div className="post-navigation">
        <Link to={`/blog/${prevPost.id}`} className="navigation-button">Previous Post</Link>
        <Link to={`/blog/${nextPost.id}`} className="navigation-button">Next Post</Link>
      </div>
    </div>
  );
};

export default BlogPost;

