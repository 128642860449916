import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import donto from '../../../Images/about-banner1.png';
import dontoAnimated from '../../../Images/cleaner.png';
import nemtVehicle from '../../../Images/nemt-welcome.png'; // Placeholder for an NEMT vehicle image
import nemtTeam from '../../../Images/cleaner.png'; // Placeholder for an image of your team

import './About.css';

const About = () => {

    useEffect(() => {
        AOS.init({
                duration: 2000,
            });
        AOS.refresh();
      }, []);
    return (
        <section className="about-wrapper">
            <Container>
                <Row>
                    <Col md={12} lg={6}>
                        <div className="about-left">
                            <img src={nemtVehicle} alt="Alliant Care Transport Vehicle" className="img-fluid" />
                            {/* <img src={nemtTeam} alt="Alliant Care Transport Team" className="img-fluid animated" /> */}
                        </div>
                    </Col>
                    <Col md={12} lg={6}>
                        <div className="about-right mt-5 mt-lg-0">
                            <div className="about-content text-start" data-aos="zoom-in">
                                <h1>Welcome to Alliant Care Transport</h1>
                                <p>Welcome to Alliant Care Transport, where we are dedicated to providing top-tier non-emergency medical transportation services. We understand the importance of reliable, safe, and comfortable travel for medical needs. Our team is trained to ensure that every journey you take with us is met with the utmost care and professionalism.</p>
                                <a href='/about'>Learn More About Us</a>
                            </div>
                            <div className="fun-fact-sec" data-aos="fade-right">
                                <div className="single-fun">
                                    <span>Personalized</span>
    				    <p>Attention to Every Detail</p>
                                </div>
                                <div className="single-fun sp-fun" data-aos="fade-right">
                                    <span>Extended Hours</span>
                                    <p>Service Availability</p>
                                </div>
                                <div className="single-fun" data-aos="fade-left">
                                    <span>Exceptional</span>
                                    <p>Customer Service</p>
                                </div>
                                <div className="single-fun sp-fun" data-aos="fade-left">
                                    <span>Community</span>
                                    <p>Focused Approach</p>
                                </div>
                                <span className="line"></span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default About;
