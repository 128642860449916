export const AboutFocus = [
    {
        "id": "001",
        "title": "Compassion",
        "description": "Compassion lies at the heart of Alliant Care Transport. We recognize that each journey has its unique challenges and needs. With this understanding, we've cultivated a service philosophy that prioritizes kindness, empathy, and attentive care. Whether it's providing a reassuring word or accommodating special requests, our goal is to make every journey feel supported and dignified.",
        "img": "https://i.ibb.co/GQmKHnh/heart.png"
    },
    {
        "id": "002",
        "title": "Reliability",
        "description": "In the realm of healthcare, reliability isn’t just a virtue—it's a necessity. Alliant Care Transport has built a reputation for dependability, ensuring that our clients arrive at their destinations safely and on time. Our operational protocols are designed to optimize efficiency while allowing for the flexibility needed to meet the dynamic needs of our clients. This commitment to reliability means that when you choose Alliant, you’re choosing a partner you can trust.",
        "img": "https://i.ibb.co/N2M8n97/love.png"
    },
    {
        "id": "003",
        "title": "Safety",
        "description": "At Alliant Care Transport, safety permeates every decision we make. From the design of our vehicles to the training of our staff, every measure is taken to ensure the utmost safety of our passengers. Our vehicles are equipped with state-of-the-art safety features, and our drivers and attendants are certified with the latest in safety protocols, ensuring peace of mind for both our clients and their loved ones.",
        "img": "https://i.ibb.co/t2cczRh/outcome.png"
    },
    {
        "id": "004",
        "title": "Outcome-Driven Service",
        "description": "We measure our success by the positive outcomes and satisfaction of our clients. This outcome-driven approach motivates us to constantly innovate and improve our services. We closely monitor feedback and engage with the communities we serve to ensure our services not only meet but exceed the evolving needs of our clients.",
        "img": "https://i.ibb.co/t2cczRh/outcome.png"
    },
    {
        "id": "005",
        "title": "Accessibility",
        "description": "Accessibility is a cornerstone of Alliant Care Transport’s service model. We are dedicated to breaking down barriers to healthcare by providing highly accessible transportation solutions. Our services cater to a wide range of mobility needs, including those requiring wheelchair access, stretcher transport, and ambulatory assistance. By ensuring our vehicles and services are accessible to everyone, we aim to foster inclusivity and enable our clients to attend essential medical appointments and treatments with ease. Our booking process is straightforward and tailored to meet the specific needs of each client, ensuring a seamless experience from start to finish.",
        "img": "https://i.ibb.co/t2cczRh/outcome.png"
    },
    {
        "id": "006",
        "title": "Personalized Care",
        "description": "Personalized care is fundamental to our philosophy at Alliant Care Transport. We understand that each client has a unique set of requirements and preferences. Our team is trained to offer not just transportation, but a customized care experience that respects the individuality of each passenger. From adjusting vehicle climate controls to personal conversations that ease anxiety, our focus is on creating a nurturing and supportive environment. This individualized approach ensures that our services not only meet the physical transportation needs of our clients but also address their emotional and psychological well-being throughout their journey with us.",
        "img": "https://i.ibb.co/t2cczRh/outcome.png"
    }
]
