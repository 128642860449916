import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
//import { FakeBlog } from '../../FakeData/blog';
import Blog from '../../Pages/Home/Blog/Blog';
import './LatestBlog.css';
import blogPosts from '../../Pages/BlogPost/BlogPost/blogPosts';

const LatestBlog = () => {
    return (
        <section className="blog-wrapper pt-6">
            <Container>
                <Row>
                    <Col sm={12} className="text-center">
                        <div className="section-title">
                            <h1>Latest Blogs</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {

                        blogPosts.slice(-3).map(blog => (
                              <Blog key={blog.id} blog={blog} />

                        ))
                    }
                </Row>

            </Container>
        </section>

    );
};

export default LatestBlog;
