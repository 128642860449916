const firebaseConfig = {
  apiKey: "AIzaSyC5I_Dc6sOPTgGn2_zLRSreCC8WZ6XJtJ4",
  authDomain: "alliant-care.firebaseapp.com",
  projectId: "alliant-care",
  storageBucket: "alliant-care.appspot.com",
  messagingSenderId: "965460534595",
  appId: "1:965460534595:web:c6c2aefc790c54c0317bf6",
  measurementId: "G-GEG2TGMS3M"
};
  
export default firebaseConfig;
