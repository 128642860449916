import feature1 from '../Images/feature11.svg';
import feature2 from '../Images/feature2.svg';
import feature3 from '../Images/feature3.svg';

export const FakeFeatures = [
    {
        "id": "001",
        "title": "Easy Scheduling",
        "description": "Our streamlined scheduling system ensures you can book transportation effortlessly, be it for regular appointments or one-off journeys.",
        "img": feature1
    },
    {
        "title": "Door-to-Door Assistance",
        "description": "We provide door-to-door service for complete assistance from pick-up to drop-off, ensuring comfort and convenience for all our clients.",
        "img": feature2
    },
    {
        "id": "003",
        "title": "Extended Service Hours",
        "description": "Our services are readily available from 6 AM to 9 PM, ensuring you can travel to and from your medical appointments with ease and reliability.",
        "img": feature3
    }
]


